import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideError } from '../redux/actions/app';

class ErrorPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: false, skip: false };
  }

  componentDidUpdate() {
    if (this.props.message !== '' && !this.state.skip) {
      this.setState({ show: true });
    } else {
      this.setState({ skip: false });
    }
  }

  handleClose = () => {
    this.setState({ show: false, skip: true }, () => {
      this.props.hideError();
    });
  };

  render() {
    const { show } = this.state;
    const { message } = this.props;
    return (
      <Modal id='errorPopup' show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.app.errorMessage,
});

const mapDispatchToProps = {
  hideError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopup);
