import {
  HIDE_ERROR,
  SHOW_ERROR,
  HIDE_LOADER,
  SHOW_LOADER,
} from "../actions/types";

const initialState = {
  errorMessage: "",
  showLoader: false,
};

export default function app(state = initialState, { type, payload }) {
  switch (type) {
    case HIDE_ERROR:
      return { ...state, errorMessage: "" };

    case SHOW_ERROR:
      const { message: errorMessage } = payload;
      return { ...state, errorMessage };

    case HIDE_LOADER:
      return { ...state, showLoader: false };

    case SHOW_LOADER:
      return { ...state, showLoader: true };

    default:
      return state;
  }
}
