import React from "react";
import { Route, Switch } from "react-router-dom";
import { Home } from "../Home";
import { Login } from "../Login";
import CategoryList from "../RegisterProduct/CategoryList";
import { ScanJob, StartScan } from "../ScanJob";
import { UnitTest } from "../UnitTest";
import SecureRoute from "./SecureRoute";
import ProductList from "../RegisterProduct/ProductList";

export default (
  <Switch>
    <SecureRoute key="home" path="/" exact component={Home} />
    <SecureRoute
      key="registerProduct"
      path="/products/categories"
      component={CategoryList}
      exact
    />
    <SecureRoute
      key="productList"
      path="/products/categories/:catId"
      component={ProductList}
    />
    <SecureRoute key="scanJob" path="/scan/job" exact component={ScanJob} />
    <SecureRoute
      key="startScan"
      path="/scan/job/:jobId"
      component={StartScan}
      exact
    />
    <Route key="login" path="/login" component={Login} />
    <Route key="unitTest" path="/unittest" exact component={UnitTest} />
    <Route key="notFound" path="*" component={() => "<h1>404 Not Found</h1>"} />
  </Switch>
);
