import * as types from "../actions/types";
import { hideLoader, showLoader } from "../actions/app";

export const loaderHandler = (store) => (next) => (action) => {
  const { dispatch } = store;
  dispatch(showHideLoader(action));
  next(action);
};

const showHideLoader = ({ type }) => (dispatch) => {
  if (
    type.endsWith("_SUCCESS") ||
    type.endsWith("_FAILED") ||
    type.endsWith("_SAVED")
  ) {
    dispatch(hideLoader());
    return;
  }
  switch (type) {
    case types.DELETE_SCANNED_ITEM:
    case types.SAVING_SCANNED_ITEMS:
    case types.FETCH_PRODUCTS:
    case types.FETCH_PRODUCT_CATEGORIES:
      dispatch(showLoader());
      break;
    default:
      break;
  }
};
