import {
  faBarcode,
  faBoxOpen,
  faCog,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/auth";
import "./NavBar.css";

const _NavBar = (props) => {
  const { developer } = props;
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Navbar.Brand as={Link} to="/">
        Bioteque ScanIt
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/scan/job">
            <FontAwesomeIcon icon={faBoxOpen} />
            New Scan Job
          </Nav.Link>
          <Nav.Link as={Link} to="/products/categories">
            <FontAwesomeIcon icon={faBarcode} />
            Register Products
          </Nav.Link>
        </Nav>
        {developer && (
          <Nav>
            <Nav.Link as={Link} to="/unittest">
              <FontAwesomeIcon icon={faCog} />
              Unit Test
            </Nav.Link>
          </Nav>
        )}
        <Nav>
          <Nav.Link href="#" onClick={props.logout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            Log out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  developer: state.auth.developer,
});

const mapDispatchToProps = {
  logout,
};

const NavBar = connect(mapStateToProps, mapDispatchToProps)(_NavBar);

export default (WrappedComponent) => (props) => {
  return (
    <>
      <NavBar />
      <div className="main-content">
        <WrappedComponent {...props} />
      </div>
    </>
  );
};
