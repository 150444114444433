import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { createScanJob, fetchNewScanData } from "../../redux/actions/scan";
import { withNavBar } from "../NavBar";
import "./ScanJob.css";

class ScanJob extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { soNumber: "", so: null, error: "" };
  }

  componentDidMount() {
    this.input.focus();
  }

  handleSoChange = (event) => {
    const { value } = event.target;
    this.setState({ soNumber: value });
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.handleSearch();
    }
  };

  handleSearch = async () => {
    if (this.state.soNumber === "") {
      return;
    }
    const so = await this.props.fetchNewScanData(this.state.soNumber);
    this.input.blur();
    if (!so) {
      this.setState({ so: null, error: "Sales Order Not found!" });
    } else {
      const error = !so.allowProcess
        ? " Sorry! Cannot start scan job for this order."
        : "";
      this.setState({ so, error });
    }
  };

  start = async () => {
    const { so } = this.state;
    const jobId = await this.props.createScanJob(so.id, so.details);
    if (!jobId) {
      return;
    }
    this.props.history.push(`/scan/job/${jobId}`);
  };

  clear = () => {
    this.setState({
      soNumber: "",
      so: null,
      error: "",
    });
  };

  continue = () => {
    const { jobId } = this.state.so.job;
    this.props.history.push(`/scan/job/${jobId}`);
  };

  renderSO() {
    const { so, error } = this.state;
    if (!so) {
      return (
        <div>{error !== "" && <Alert variant="danger">{error}</Alert>}</div>
      );
    }
    return (
      <div>
        {error && <Alert variant="danger">{error}</Alert>}
        <h5>
          {`SO# ${so.id}`}
          <span>{so.txtStatus}</span>
        </h5>
        <div className="info">
          <div>{`Order Date: ${so.orderDate}`}</div>
          <div className="section">
            <div>
              <b>Ship to:</b>
            </div>
            <div>{so.company}</div>
            <div>{so.sAddress1}</div>
            <div>{`${so.sCity}, ${so.sState} ${so.sZip}`}</div>
          </div>
          <div className="section">
            <div>
              <b>Ship Method:</b>
              <span>{so.txtShipMethod}</span>
            </div>
          </div>
          <div className="section">
            <div>
              <b>Sales:</b>
              <span>{so.txtSalesPerson}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderButton() {
    // if (this.state.so) return null;
    const { so } = this.state;
    if (!so) {
      return null;
    }
    const hasJob = so.job !== null && so.job.status === 'open';
    return (
      <div>
        <hr />
        <div className="buttonContainer">
          {so.allowProcess && !hasJob && (
            <Button variant="warning" onClick={this.start}>
              Start
            </Button>
          )}
          {so.allowProcess && hasJob && (
            <Button variant="warning" onClick={this.continue}>
              {`Continue ${so.job.jobId}`}
            </Button>
          )}
          <Button variant="clear" onClick={this.clear}>
            Clear
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <h5>New Scan Job</h5>
        <Form>
          <Form.Label>Enter Sales Order Number:</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              ref={(ref) => (this.input = ref)}
              placeholder="SO number"
              aria-label="SO number"
              aria-describedby="basic-addon2"
              type="number"
              value={this.state.soNumber}
              onChange={this.handleSoChange}
              onKeyDown={this.handleEnter}
            />
            <InputGroup.Append>
              <Button variant="outline-secondary" onClick={this.handleSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <Form.Text className="text-muted">
            You can only start a new scan job for non-completed sales orders.
          </Form.Text>
        </Form>
        <hr />
        {this.renderSO()}
        {this.renderButton()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  so: state.scan.so,
});

const mapDispatchToProps = {
  fetchNewScanData,
  createScanJob,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavBar(ScanJob));
