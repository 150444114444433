import {
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  REGISTER_PRODUCT_SUCCESS,
} from "../actions/types";

const initialState = {
  categories: [],
  products: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCT_CATEGORIES_SUCCESS: {
      const { categories } = payload;
      return { ...state, categories };
    }

    case FETCH_PRODUCTS_SUCCESS: {
      const { products } = payload;
      return { ...state, products };
    }

    case REGISTER_PRODUCT_SUCCESS: {
      const { barcode, productId } = payload;
      const products = [...state.products];

      // remove old barcode
      const ndx2 = products.findIndex((p) => p.barcode === barcode);
      if (ndx2 !== -1) {
        products[ndx2] = { ...products[ndx2], barcode: "" };
      }

      // add new barcode
      const ndx = products.findIndex(
        (p) => parseInt(p.productID) === parseInt(productId)
      );
      if (ndx !== -1) {
        products[ndx] = { ...products[ndx], barcode };
      }

      return { ...state, products };
    }

    default:
      return state;
  }
};
