export const saveIdToken = (idToken, expDate, developer) => {
  setCookieWithExpDate("idToken", idToken, expDate);
  setCookieWithExpDate("expDate", expDate.getTime(), expDate);
  setCookieWithExpDate("developer", developer, expDate);
};

export const getIdToken = () => {
  const idToken = getCookie("idToken") || null;
  const expDate = getCookie("expDate") || -1;
  const developer = getCookie("developer") || false;
  if (!idToken || expDate <= new Date().getTime()) {
    clearIdToken();
  }
  return { idToken, expDate, developer };
};

export const clearIdToken = () => {
  setCookie("idToken", "", -1);
  setCookie("expDate", "", -1);
  setCookie("developer", false, -1);
};

//
// cookies
//
const setCookie = (name, value, expireDays = -1) => {
  const d = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  setCookieWithExpDate(name, value, d);
};

const setCookieWithExpDate = (name, value, expireDate) => {
  const expires = "expires=" + expireDate.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

const getCookie = (name) => {
  let _name = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(_name) === 0) {
      return c.substring(_name.length, c.length);
    }
  }
  return "";
};
