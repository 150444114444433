import * as types from "./types";
import { getIdToken } from "../../api/util";

export const appStarted = () => (dispatch) => {
  const payload = getIdToken();
  dispatch({
    type: types.APP_STARTED,
    payload,
  });
};

export const showError = (message) => ({
  type: types.SHOW_ERROR,
  payload: { message },
});

export const hideError = () => ({
  type: types.HIDE_ERROR,
});

export const showLoader = () => ({
  type: types.SHOW_LOADER,
});

export const hideLoader = () => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: types.HIDE_LOADER });
  }, 500);
};
