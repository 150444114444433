import { APP_STARTED, LOGIN_SUCCESS } from "../actions/types";

const initialState = {
  idToken: undefined,
  developer: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_STARTED: {
      const { idToken, expDate, developer } = payload;
      if (idToken) {
        return { ...state, idToken, expDate, developer };
      }
      return state;
    }

    case LOGIN_SUCCESS: {
      const { idToken, expDate, developer } = payload;
      return { ...state, idToken, expDate, developer };
    }

    default:
      return state;
  }
};
