import { API_URL_PREFIX, API_CLIENT_ID } from "../conf/config";
import axios from "axios";
import { getIdToken, saveIdToken } from "./util";

const apiUrl = (method) => API_URL_PREFIX.concat(method);

const httpHeaders = {
  accessToken: (accessToken) => ({
    Authorization: `access_token ${accessToken}`,
  }),
  idToken: (idToken) => ({ Authorization: `Bearer ${idToken}` }),
  formData: () => ({ "Content-Type": "application/x-www-form-urlencoded" }),
  json: () => ({ "Content-Type": "application/json" }),
};

const getAuthHeaders = () => {
  const { idToken } = getIdToken();
  return {
    headers: { ...httpHeaders.json(), ...httpHeaders.idToken(idToken) },
  };
};

const ts = () => {
  const d = new Date();
  return d.getTime();
};

export const authorize = () => {
  const url = apiUrl("/access_token");
  const data = { clientId: API_CLIENT_ID, origin: window.location.href };
  const configs = { headers: httpHeaders.json() };
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const login = (accessToken, { username, password }) => {
  const url = apiUrl("/auth_login");
  const data = { clientId: API_CLIENT_ID, username, password };
  const configs = {
    headers: { ...httpHeaders.json(), ...httpHeaders.accessToken(accessToken) },
  };
  return axios.post(url, data, configs).then((response) => {
    const { idToken, expires, developer } = response.data;
    const expDate = new Date(expires * 1000);
    saveIdToken(idToken, expDate, developer);
    return { idToken, expDate: expDate.getTime(), developer };
  });
};

export const fetchScanJobs = (status = "open") => {
  const url = apiUrl("/scan/jobs");
  const data = { status };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const fetchScanJob = (jobId) => {
  const url = apiUrl(`/scan/jobs/${jobId}?${ts()}`);
  const configs = getAuthHeaders();
  return axios.get(url, configs).then((response) => {
    return response.data;
  });
};

export const fetchScanJobBySoId = (soId) => {
  const url = apiUrl(`/scan/jobs/so/${soId}?${ts()}`);
  const configs = getAuthHeaders();
  return axios.get(url, configs).then((response) => {
    return response.data;
  });
};

export const fetchSalesOrder = (soId) => {
  const url = apiUrl(`/salesOrder/${soId}`);
  const data = null;
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const fetchBarCodeData = (barcode) => {
  const url = apiUrl(`/barcode/product/search`);
  const data = { barcode };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const fetchProductInfo = (sku) => {
  const url = apiUrl(`/products/search`);
  const data = { sku };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const registerProduct = (barcode, productId, type) => {
  const url = apiUrl(`/barcode/product/register`);
  const data = { barcode, productId, type };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const unRegisterProduct = (barcode) => {
  const url = apiUrl(`/barcode/${barcode}`);
  const configs = getAuthHeaders();
  return axios.delete(url, configs).then((response) => {
    return response.data;
  });
};

export const createScanJob = (soId, items) => {
  const url = apiUrl(`/scan/jobs/create/${soId}`);
  const data = { items };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs).then((response) => {
    return response.data;
  });
};

export const deleteScanJob = (jobId) => {
  const url = apiUrl(`/scan/jobs/${jobId}`);
  const configs = getAuthHeaders();
  return axios.delete(url, configs).then((response) => {
    return response.data;
  });
};

export const updateScanJob = (jobId, data) => {
  const url = apiUrl(`/scan/jobs/${jobId}`);
  const configs = getAuthHeaders();
  return axios.post(url, data, configs);
};

export const updateTrackingNumber = (jobId, trackingNumber) => {
  const url = apiUrl(`/scan/tracking/${jobId}`);
  const data = { trackingNumber };
  const configs = getAuthHeaders();
  return axios.post(url, data, configs);
};

export const fetchProductCategories = () => {
  const url = apiUrl(`/products/categories?${ts()}`);
  const configs = getAuthHeaders();
  return axios.get(url, configs).then((response) => {
    return response.data;
  });
};

export const fetchProductList = (catId) => {
  const url = apiUrl(`/productCategory/${catId}?${ts()}`);
  const configs = getAuthHeaders();
  return axios.get(url, configs).then((response) => {
    return response.data;
  });
};
