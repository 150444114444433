import React from 'react';

export default () => {
  return (
    <img
      className='logo'
      src={require('../assets/images/bioteque-logo-new-white.png')}
      alt='Bioteque Logo'
    />
  );
};
