import { faArrowLeft, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchProducts } from "../../redux/actions/product";
import { registerProduct } from "../../redux/actions/scan";
import Scanner from "../../services/Scanner/index";
import { withNavBar } from "../NavBar";
import "./ProductList.css";

class ProductList extends PureComponent {
  constructor(props) {
    super(props);
    const { catId } = props.match.params;
    const { category } = props.location.state;
    this.state = {
      catId,
      category,
      showScan: false,
      registered: "",
      showClose: true,
    };
    this.scanner = new Scanner();
    this.product = null;
  }

  componentDidMount() {
    this.props.fetchProducts(this.state.catId);
    this.startScan();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.scanner.stop();
  }

  startScan() {
    this.scanner.start({
      onScan: this.onScan,
      type: "product",
    });
  }

  onScan = ({ sCode, iQty, gtin, expDate, lotNumber, serialNumber, type }) => {
    if (this.product !== null) {
      this.register(this.product.productID, gtin, type);
    }
  };

  register = async (productId, barcode, type) => {
    const complete = await this.props.registerProduct(barcode, productId, type);
    if (complete) {
      this.setState({ registered: "Registered!", showClose: false });
      setTimeout(() => {
        this.closeScan();
      }, 2000);
      return;
    }
    this.setState({ registered: "Failed!", showClose: true });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  onSelectProduct = (product) => {
    this.product = product;
    this.openScan();
  };

  openScan = () => {
    this.setState({ showScan: true, showClose: true });
  };

  closeScan = () => {
    this.product = null;
    this.setState({ showScan: false, showClose: true, registered: "" });
  };

  renderItem = (product, index) => {
    const key = `p_${product.productID}`;
    const cls = product.barcode ? "active" : "";
    return (
      <div
        className="productItem"
        key={key}
        onClick={() => {
          this.onSelectProduct(product);
        }}
      >
        <div className={cls}>
          <b>{product.sku}</b>
          <label className="barcode">
            <span>
              <FontAwesomeIcon icon={faBarcode} /> {product.barcode}
            </span>
            <FontAwesomeIcon icon={faBarcode} />
          </label>
        </div>
        <div>{product.name}</div>
      </div>
    );
  };

  renderList() {
    const { products } = this.props;
    if (products.length < 1) {
      return null;
    }
    return (
      <div className="section">
        <div className="subtitle">Tap a Product and Scan</div>
        <Row className="categoryProductList">
          {products.map(this.renderItem)}
        </Row>
      </div>
    );
  }

  renderScanOverlay() {
    return (
      this.state.showScan && (
        <div className="qtyUpdater scanTracking scanProduct">
          <label className="text-center">
            <span>Scanning...</span>
            <br />
            {this.product.sku}
            <br />
            {this.product.name}
          </label>
          <div className="confirmMessage">{this.state.registered}</div>
          {this.state.showClose && (
            <Button
              variant="primary"
              className="closeQty"
              onClick={this.closeScan}
            >
              Close
            </Button>
          )}
        </div>
      )
    );
  }

  render() {
    const { category } = this.state;
    return (
      <Container>
        <div className="btnBack" onClick={this.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Categories
        </div>
        <h5>{category.category}</h5>
        {this.renderList()}
        {this.renderScanOverlay()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.product.products,
});

const mapDispatchToProps = {
  fetchProducts,
  registerProduct,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavBar(ProductList));
