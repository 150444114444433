import onScan from 'onscan.js/onscan.min.js';

export default class Scanner {
  type = 'product';

  setType(type) {
    this.type = type;
  }

  start({ onScan: onScanned, type = 'product' }) {
    this.setType(type);
    this.onScanCallBack = onScanned;
    onScan.attachTo(document, {
      onScan: this.onScan,
      // onKeyDetect: this.onKeyDetect //(iKeyCode)
      keyCodeMapper: this.keyCodeMapper,
    });

    // for test only!!! Comment out the following lines after testing.
    // setTimeout(() => {
    //   onScan.simulate(document, '0104719894432007112305121726051110A230501');
    // }, 3000);
  }

  stop() {
    onScan.detachFrom(document);
  }

  onScan = (sCode, iQty) => {
    if (this.type === '' || !this.onScanCallBack) {
      return;
    }

    if (this.isType3(sCode)) {
      this.type = 'type3';
      const data = this.parseType3BarCode(sCode, iQty);
      return this.onScanCallBack({ ...data, type: this.type });
    }

    if (this.type === 'product') {
      const data = this.parseProductBarCode(sCode, iQty);
      return this.onScanCallBack({ ...data, type: this.type });
    }

    if (this.type === 'gem') {
      const data = this.praseGEMLBarCode(sCode, iQty);
      return this.onScanCallBack({ ...data, type: this.type });
    }

    const _sCode = sCode.replace(/\*/g, '');
    return this.onScanCallBack({ sCode: _sCode, type: this.type });
  };

  keyCodeMapper = (oEvent) => {
    const iCode = oEvent.which || oEvent.keyCode;
    switch (true) {
      case iCode >= 48 && iCode <= 90: // numbers and letters
      case iCode >= 106 && iCode <= 111: // operations on numeric keypad (+, -, etc.)
        if (oEvent.key !== undefined && oEvent.key !== '') {
          return oEvent.key;
        }
        var sDecoded = String.fromCharCode(iCode);
        switch (oEvent.shiftKey) {
          case false:
            sDecoded = sDecoded.toLowerCase();
            break;
          case true:
            sDecoded = sDecoded.toUpperCase();
            break;
          default:
            break;
        }
        return sDecoded;
      case iCode >= 96 && iCode <= 105: // numbers on numeric keypad
        return 0 + (iCode - 96);
      case iCode === 16:
        return '*';
      default:
        return '';
    }
  };

  parseProductBarCode = (sCode, iQty) => {
    // GTIN: (01) 14 digits
    // EXP: (17) 6 digits
    // LOT: (10) 5 to 7
    // SER: (21) ...

    const arr = sCode.split('*');

    if (sCode.length <= 16) {
      return {
        sCode,
        iQty,
        gtin: arr[0],
        lotNumber: arr[1] || '',
        serialNumber: '',
        expDate: '',
      };
    }

    let barcode = arr.join('');

    // get GTIN
    const gtin = barcode.substr(2, 14); // (01) + 14 digits

    // get expire
    const expDate = barcode.substr(18, 6); // (17) + 6 digits

    // remove gtin and expDate
    barcode = barcode.replace('01' + gtin, '').replace('17' + expDate, '');

    let lotNumber = '';
    let serialNumber = '';

    // find lot number
    const lotNumIndex = barcode.indexOf('10'); // (10) + 5 letters/digits
    if (lotNumIndex < 0) {
      return {
        sCode,
        iQty,
        gtin,
        lotNumber,
        serialNumber,
        expDate,
        error: 'Cannot find lot number',
      };
    }

    // check next prefix
    const nextPrefix = barcode.substr(0, 2);
    if (nextPrefix === '11') {
      // try gem type;
      this.type = 'gem';
      return this.praseGEMLBarCode(sCode, iQty);
    }

    // remove lot number prefix
    barcode = barcode.substr(2);

    const serialNumLength = 6; // 6 letters
    if (barcode.length <= serialNumLength) {
      lotNumber = barcode;
      return { sCode, iQty, gtin, lotNumber, serialNumber, expDate };
    }

    // find serial number prefix
    const serialNumIndex = barcode.indexOf(
      '21',
      barcode.length - serialNumLength
    ); // (21) + 4 letters/digits(?)
    if (serialNumIndex >= serialNumLength) {
      lotNumber = barcode.substr(0, serialNumIndex);
      serialNumber = barcode.substr(serialNumIndex + 2);
    } else {
      lotNumber = barcode;
    }

    return { sCode, iQty, gtin, lotNumber, serialNumber, expDate };
  };

  praseGEMLBarCode = (sCode, iQty) => {
    // GTIN: (01) 14 digits
    // EXP: (17) 6 digits
    // Manufactured Date: (11) 6 digits
    // Lot: (10) ...

    const arr = sCode.split('*');

    if (sCode.length <= 16) {
      return {
        sCode,
        iQty,
        gtin: arr[0],
        lotNumber: arr[1] || '',
        serialNumber: '',
        expDate: '',
        manufacturedDate: '',
      };
    }

    let barcode = arr.join('');

    // get GTIN
    const gtin = barcode.substr(2, 14); // (01) + 14 digits

    // get expire
    const expDate = barcode.substr(18, 6); // (17) + 6 digits

    // remove gtin and expDate
    barcode = barcode.replace('01' + gtin, '').replace('17' + expDate, '');

    // check next prefix
    const nextPrefix = barcode.substr(0, 2);
    if (nextPrefix === '10') {
      // try product type;
      this.type = 'product';
      return this.parseProductBarCode(sCode, iQty);
    }

    let lotNumber = '';
    let serialNumber = '';
    let manufacturedDate = '';

    // manufactured date
    const manDateIndex = barcode.indexOf('11'); // (11) + 6 digits
    if (manDateIndex < 0) {
      return {
        sCode,
        iQty,
        gtin,
        lotNumber,
        serialNumber,
        manufacturedDate,
        expDate,
        error: 'Cannot find manufactured date',
      };
    }

    // get manufactured date
    manufacturedDate = barcode.substr(manDateIndex + 2, 6);

    // remove manufactured date.
    barcode = barcode.substr(manDateIndex + 8);

    // find lot number
    const lotNumIndex = barcode.indexOf('10'); // (10) + 5 letters/digits?
    if (lotNumIndex < 0) {
      return {
        sCode,
        iQty,
        gtin,
        lotNumber,
        serialNumber,
        manufacturedDate,
        expDate,
        error: 'Cannot find lot number',
      };
    }

    // remove lot number prefix
    barcode = barcode.substr(2);

    lotNumber = barcode;
    return {
      sCode,
      iQty,
      gtin,
      lotNumber,
      serialNumber,
      manufacturedDate,
      expDate,
    };
  };

  isType3 = (sCode) => {
    // (01) GTIN: 14 digits
    // (11) Manufactured Date:  6 digits
    // (17) EXP: 6 digits
    // Lot: (10) ...

    const arr = sCode.split('*');
    const barcode = arr.join('');
    if (barcode.length <= 16) {
      return false;
    }
    const regex = /^01(\d{14})11(\d{6})17(\d{6})10(.+)$/;
    return regex.test(barcode);
  };

  parseType3BarCode = (sCode, iQty) => {
    const arr = sCode.split('*');
    const barcode = arr.join('');

    // get GTIN
    const gtin = barcode.substr(2, 14); // (01) + 14 digits

    // get manufacture date
    const manufacturedDate = barcode.substr(18, 6); // (11) + 6 digits

    // get expire date
    const expDate = barcode.substr(26, 6); // (17) + 6 digits

    // get lot number
    const lotNumber = barcode.substr(34); // (17) + 6 digits

    return {
      sCode,
      iQty,
      gtin,
      lotNumber,
      serialNumber: '',
      manufacturedDate,
      expDate,
    };
  };
}
