import * as api from "../../api";
import { showError } from "./app";
import * as types from "./types";

export const fetchProductCategories = () => async (dispatch, getState) => {
  const { categories } = getState().product;
  if (categories.length > 0) {
    return;
  }

  dispatch({ type: types.FETCH_PRODUCT_CATEGORIES });
  try {
    const data = await api.fetchProductCategories();
    dispatch({ type: types.FETCH_PRODUCT_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.FETCH_PRODUCT_CATEGORIES_FAILED });
    dispatch(showError("Unable to load product categories!"));
    return;
  }
};

export const fetchProducts = (catId) => async (dispatch) => {
  dispatch({ type: types.FETCH_PRODUCTS });
  try {
    const data = await api.fetchProductList(catId);
    dispatch({
      type: types.FETCH_PRODUCTS_SUCCESS,
      payload: { ...data, categoryId: catId },
    });
  } catch (error) {
    dispatch({ type: types.FETCH_PRODUCTS_FAILED });
    dispatch(showError("Unable to load product categories!"));
    return;
  }
};
