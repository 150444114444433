export const APP_STARTED = "APP_STARTED";
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const FETCH_SCAN_JOBS = "FETCH_SCAN_JOBS";
export const FETCH_SCAN_JOBS_SUCCESS = "FETCH_SCAN_JOBS_SUCCESS";
export const FETCH_SCAN_JOBS_FAILED = "FETCH_SCAN_JOBS_FAILED";

export const FETCH_SCAN_JOB = "FETCH_SCAN_JOB";
export const FETCH_SCAN_JOB_SUCCESS = "FETCH_SCAN_JOB_SUCCESS";
export const FETCH_SCAN_JOB_FAILED = "FETCH_SCAN_JOB_FAILED";
export const FETCH_SCAN_JOB_SO_SUCCESS = "FETCH_SCAN_JOB_SO_SUCCESS";

export const FETCH_SALES_ORDER = "FETCH_SALES_ORDER";
export const FETCH_SALES_ORDER_SUCCESS = "FETCH_SALES_ORDER_SUCCESS";
export const FETCH_SALES_ORDER_FAILED = "FETCH_SALES_ORDER_FAILED";

export const FETCH_BARCODE_DATA = "FETCH_BARCODE_DATA";
export const FETCH_BARCODE_DATA_SUCCESS = "FETCH_BARCODE_DATA_SUCCESS";
export const FETCH_BARCODE_DATA_FAILED = "FETCH_BARCODE_DATA_FAILED";

export const FETCH_PRODUCT_DATA = "FETCH_PRODUCT_DATA";
export const FETCH_PRODUCT_DATA_SUCCESS = "FETCH_PRODUCT_DATA_SUCCESS";
export const FETCH_PRODUCT_DATA_FAILED = "FETCH_PRODUCT_DATA_FAILED";

export const REGISTER_PRODUCT = "REGISTER_PRODUCT";
export const REGISTER_PRODUCT_SUCCESS = "REGISTER_PRODUCT_SUCCESS";
export const REGISTER_PRODUCT_FAILED = "REGISTER_PRODUCT_FAILED";

export const UNREGISTER_PRODUCT = "UNREGISTER_PRODUCT";
export const UNREGISTER_PRODUCT_SUCCESS = "UNREGISTER_PRODUCT_SUCCESS";
export const UNREGISTER_PRODUCT_FAILED = "UNREGISTER_PRODUCT_FAILED";

export const CREATE_SCAN_JOB = "CREATE_SCAN_JOB";
export const CREATE_SCAN_JOB_SUCCESS = "CREATE_SCAN_JOB_SUCCESS";
export const CREATE_SCAN_JOB_FAILED = "CREATE_SCAN_JOB_FAILED";

export const DELETE_SCAN_JOB = "DELETE_SCAN_JOB";
export const DELETE_SCAN_JOB_SUCCESS = "DELETE_SCAN_JOB_SUCCESS";
export const DELETE_SCAN_JOB_FAILED = "DELETE_SCAN_JOB_FAILED";

export const SCANNED_DATA = "SCANNED_DATA";
export const RESET_COUNTER = "RESET_COUNTER";
export const DELETE_SCANNED_ITEM = "DELETE_SCANNED_ITEM";
export const UPDATE_SCANNED_ITEM_QTY = "UPDATE_SCANNED_ITEM_QTY";
export const SAVING_SCANNED_ITEMS = "SAVING_SCANNED_ITEMS";
export const SCANNED_ITEMS_SAVED = "SCANNED_ITEMS_SAVED";
export const SCANNED_ITEMS_SAVE_FAILED = "SCANNED_ITEMS_SAVE_FAILED";

export const UPDATE_TRACKING_NUMBER = "UPDATE_TRACKING_NUMBER";

export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILED =
  "FETCH_PRODUCT_CATEGORIES_FAILED";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";
