import {
  faArrowCircleRight,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import { Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchScanJobs } from "../../redux/actions/scan";
import { withNavBar } from "../NavBar";
import "./Home.css";

class Home extends PureComponent {
  componentDidMount() {
    this.props.fetchScanJobs("open");
  }

  continueJob(jobId) {
    this.props.history.push(`/scan/job/${jobId}`);
  }

  renderJobs = () => {
    const { scanJobs } = this.props;

    if (scanJobs.length < 1) {
      return null;
    }

    return (
      <>
        <hr />
        <h5>In Progress</h5>
        <Table striped hover size="sm" className="jobList">
          <thead>
            <tr>
              <th>SO ID</th>
              <th>Job ID</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {scanJobs.map((job, index) => {
              return (
                <tr key={index}>
                  <td>{job.soID}</td>
                  <td>{job.jobId}</td>
                  <td className="text-center action">
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      onClick={() => this.continueJob(job.jobId)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  render() {
    return (
      <Container>
        <div id="scanJobContainer" className="text-center  main-btn">
          <Link to="/scan/job" id="scanJob" variant="warning" className="btn">
            <FontAwesomeIcon icon={faBoxOpen} /> New Scan Job
          </Link>
        </div>
        {this.renderJobs()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  scanJobs: state.scan.jobs,
});

const mapDispatchToProps = {
  fetchScanJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavBar(Home));
