import React, { PureComponent } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchProductCategories } from "../../redux/actions/product";
import { withNavBar } from "../NavBar";
import "./CategoryList.css";

class CategoryList extends PureComponent {
  componentDidMount() {
    this.props.fetchProductCategories();
  }

  onSelectCategory = (cat) => {
    this.props.history.push({
      pathname: `/products/categories/${cat.id}`,
      state: { category: cat },
    });
  };

  renderList() {
    const { categories } = this.props;
    if (categories.length < 1) {
      return null;
    }
    return (
      <div className="section">
        <div className="step">Please Select a Categories</div>
        <div className="categoryList">
          {categories.map((cat) => {
            return (
              <Button
                key={cat.id}
                variant="primary"
                onClick={() => {
                  this.onSelectCategory(cat);
                }}
              >
                {cat.category}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <h5>Register Products</h5>
        {this.renderList()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.product.categories,
});

const mapDispatchToProps = {
  fetchProductCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavBar(CategoryList));
