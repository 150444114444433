import * as api from '../../api';
import { showError } from './app';
import * as types from './types';

export const login = ({ username, password }) => async (dispatch) => {
  dispatch({ type: types.LOGIN });
  try {
    const { accessToken } = await api.authorize();
    const data = await api.login(accessToken, { username, password });
    console.log(data);
    dispatch({ type: types.LOGIN_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    dispatch({ type: types.LOGIN_FAILED });
    dispatch(showError('Unable to login! Please try again.'));
    return;
  }
};

export const logout = () => ({
  type: types.LOGOUT,
});
