import onScan from 'onscan.js/onscan.min.js';
import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Scanner from '../../services/Scanner/index';
import './UnitTest.css';

class UnitTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.scanner = new Scanner();
  }

  componentDidMount() {
    this.startScan();
  }

  componentWillUnmount() {
    this.scanner.stop();
  }

  startScan() {
    this.scanner.start({
      onScan: this.onScan,
      type: 'product',
    });
  }

  onScan = async ({
    sCode,
    iQty,
    gtin,
    expDate,
    lotNumber,
    serialNumber,
    type,
    error = null,
  }) => {
    let text = 'Type: ' + type + '\n';
    if (type === 'product' || type === 'gem' || type === 'type3') {
      text += 'GTIN: ' + gtin + '\n';
      text += 'Lot #: ' + lotNumber + '\n';
      text += 'Exp Date: ' + expDate + '\n';
      text += 'SerialNumber #: ' + serialNumber + '\n';
    }
    text += sCode + '\n';

    if (error) {
      text += '\nError: ' + error + '\n\n';
    }

    this.output(text);
  };

  output = (str) => {
    this.setState((prevState) => {
      const text = str + '\n' + prevState.text;
      return { text };
    });
  };

  clear = () => {
    this.setState({ text: '' });
  };

  setType = (type) => {
    this.scanner.setType(type);
    this.output('Set type to: ' + type);
  };

  removeType = () => {
    this.setType('');
  };

  setHyphenType = () => {
    this.setType('-');
  };

  setProductType = () => {
    this.setType('product');
  };

  setGEMType = () => {
    this.setType('gem');
  };

  simulate = (str) => {
    onScan.simulate(document, str);
  };

  test1 = () => {
    const barcode = '116200*F90667';
    this.simulate(barcode);
  };

  test2 = () => {
    const barcode = '01047153091203471732103110*F221104210127';
    // const barcode = "01047153091203781733022810F230321210046";
    this.simulate(barcode);
  };

  test3 = () => {
    const barcode = '010471530912107817280531*10F806*Y4210014';
    this.simulate(barcode);
  };

  test4 = () => {
    const barcode = '01047153091210781730013*110F20026*E210010';
    this.simulate(barcode);
  };

  test5 = () => {
    const barcode = '01069242749551151725080711220808102208047';
    this.simulate(barcode);
  };

  testType3 = () => {
    const barcode = '0104719894432007112305121726051110A230501';
    this.simulate(barcode);
  };

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ text: value });
  };

  render() {
    const { text } = this.state;
    return (
      <Container>
        <div className='testHeader'>
          <Link to='/' style={{ float: 'right' }}>
            Home
          </Link>
          <h5>Unit Test</h5>
        </div>

        <div>
          <b>Please scan a barcode to debug...</b>
        </div>

        <div className='testBtnContainer'>
          <label>Test:</label>
          <div>
            <button onClick={this.test1}>Test 1</button>
            <button onClick={this.test2}>Test 2</button>
            <button onClick={this.test3}>Test 3</button>
            <button onClick={this.test4}>Test 4</button>
            <button onClick={this.test5}>Test GEM</button>
            <button onClick={this.testType3}>Type III</button>
          </div>
        </div>
        <div className='testBtnContainer'>
          <label>Type: </label>
          <div>
            <button onClick={this.setProductType}>Product</button>
            <button onClick={this.setGEMType}>GEM</button>
            <button onClick={this.removeType}>None</button>
            <button onClick={this.setHyphenType}>-</button>
          </div>
        </div>
        <textarea
          style={{
            marginTop: 20,
            width: '100%',
            height: 250,
            backgroundColor: 'lightblue ',
          }}
          value={text}
          onChange={this.handleChange}
        />
        <button onClick={this.clear} style={{ width: '100%' }}>
          Clear
        </button>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UnitTest);
