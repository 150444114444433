import React, { PureComponent } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ReactComponent as BarCodeIcon } from "../../assets/images/barcode.svg";
import Logo from "../../components/Logo";
import "./Login.css";
import { connect } from "react-redux";
import { login } from "../../redux/actions/auth";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
  }

  componentDidMount() {
    document.body.classList.add("login");
    this.checkLogin();
  }

  componentDidUpdate() {
    document.body.classList.remove("login");
    this.checkLogin();
  }

  checkLogin() {
    if (this.props.idToken) {
      this.props.history.push("/");
    }
  }

  handleChange = (event, field) => {
    const { value } = event.target;
    this.setState({ [field]: value });
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.handleSubmit();
    }
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.login(this.state);
  };

  render() {
    return (
      <Container className="login">
        <Col md={12} lg={{ span: 4, offset: 4 }}>
          <Logo />
          <BarCodeIcon fill="#3D8CAC" width="100" height="100" />
          <Form>
            <Form.Group controlId="formGroupUsername">
              <Form.Control
                type="text"
                placeholder="Username"
                value={this.state.value}
                onChange={(e) => this.handleChange(e, "username")}
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                value={this.state.value}
                onChange={(e) => this.handleChange(e, "password")}
                onKeyDown={this.handleEnter}
              />
            </Form.Group>
            <Button type="button" onClick={this.handleSubmit}>
              Login
            </Button>
          </Form>
          <div className="version">{`Version ${process.env.REACT_APP_VERSION}`}</div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  idToken: state.auth.idToken,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
