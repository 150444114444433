import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { clearIdToken } from "../../api/util";
import { LOGOUT } from "../../redux/actions/types";
import { loaderHandler } from "../../redux/middlewares/loaderHandler";
import * as reducers from "../../redux/reducers";

// The order of middle wares matters.
const middleWares = [thunk, loaderHandler];

// these middleWares are only for development.
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middleWares.push(logger);
}

const reducer = combineReducers(reducers);
const appReducer = (state, action) => {
  let appState = state;
  if (action.type === LOGOUT) {
    clearIdToken();
    appState = undefined;
  }
  return reducer(appState, action);
};

const store = compose(applyMiddleware(...middleWares))(createStore)(appReducer);

export default store;
