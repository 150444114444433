import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorPopup from "../../components/ErrorPopup";
import Loader from "../../components/Loader";
import { appStarted } from "../../redux/actions/app";
import "./App.css";
import routes from "./routes";
import store from "./store";

store.dispatch(appStarted());

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>{routes}</Router>
        <Loader />
        <ErrorPopup />
      </Provider>
    );
  }
}

export default App;
