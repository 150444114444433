import React from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

const Loader = ({ show = false }) => {
  if (!show) {
    return null;
  }
  return (
    <div style={styles.container}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

const mapStateToProps = (state) => ({
  show: state.app.showLoader,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);

const styles = {
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
};
