import {
  CREATE_SCAN_JOB_SUCCESS,
  FETCH_SALES_ORDER_SUCCESS,
  FETCH_SCAN_JOBS_SUCCESS,
  DELETE_SCAN_JOB_SUCCESS,
  FETCH_SCAN_JOB_SUCCESS,
  FETCH_BARCODE_DATA_SUCCESS,
  SCANNED_DATA,
  RESET_COUNTER,
  DELETE_SCANNED_ITEM,
  UPDATE_SCANNED_ITEM_QTY,
  FETCH_SCAN_JOB_SO_SUCCESS,
  UPDATE_TRACKING_NUMBER,
} from "../actions/types";

const initialState = {
  jobs: [],
  so: {},
  progress: {},
  barcodes: {},
  noRemaining: false,
  itemNotFound: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SCAN_JOBS_SUCCESS: {
      const { jobs } = payload;
      return { ...state, jobs };
    }

    case FETCH_SALES_ORDER_SUCCESS: {
      const { so, details } = payload;
      return { ...state, so: { ...so, details } };
    }

    case FETCH_SCAN_JOB_SUCCESS: {
      const { job } = payload;
      const progress = { ...state.progress };
      progress[job.jobId] = job;
      return { ...state, progress };
    }

    case FETCH_SCAN_JOB_SO_SUCCESS:
      const { jobs } = payload;
      if (jobs.length < 1) {
        return state;
      }
      const [job] = jobs;
      const progress = { ...state.progress };
      progress[job.jobId] = job;
      return { ...state, progress };

    case FETCH_BARCODE_DATA_SUCCESS:
      const { barcode, product } = payload;
      const barcodes = { ...state.barcodes };
      barcodes[barcode] = product;
      return { ...state, barcodes };

    case CREATE_SCAN_JOB_SUCCESS: {
      const { job } = payload;
      const _progress = { ...state.progress };
      if (_progress[job.jobId]) {
        return state;
      }
      _progress[job.jobId] = job;
      return { ...state, progress: _progress };
    }

    case DELETE_SCAN_JOB_SUCCESS: {
      const { jobId } = payload;
      const progress = { ...state.progress };
      if (progress[jobId]) {
        delete progress[jobId];
      }
      const jobs = state.jobs.filter((j) => {
        return j.jobId !== jobId;
      });
      return { ...state, progress, jobs };
    }

    case SCANNED_DATA: {
      const { jobId, scannedData } = payload;
      const { gtin, lotNumber } = scannedData;
      const product = state.barcodes[gtin];
      if (!product) {
        return state;
      }
      const progress = { ...state.progress };
      const items = [...progress[jobId].items];
      const itemIndex = items.findIndex(
        (item) => item.productID === product.id
      );

      if (itemIndex === -1) {
        return { ...state, itemNotFound: true };
      }

      const itemQty = items[itemIndex].qty;

      if (typeof items[itemIndex].remaining === undefined) {
        items[itemIndex].remaining = itemQty;
      }

      let scanned;
      if (!items[itemIndex].scanned) {
        items[itemIndex].scanned = [];
        scanned = [];
      } else {
        scanned = [...items[itemIndex].scanned];
      }

      let scannedTotal = 0;
      scanned.forEach((scanItem) => {
        scannedTotal += parseInt(scanItem.qty);
      });

      items[itemIndex].remaining = itemQty - scannedTotal;

      if (items[itemIndex].remaining < 1) {
        return { ...state, noRemaining: true };
      }

      const scannedIndex = scanned.findIndex(
        (scanItem) => scanItem.lotNumber === lotNumber
      );

      if (scannedIndex !== -1) {
        scanned[scannedIndex].qty += 1;
      } else {
        scanned.push({ gtin, lotNumber, qty: 1 });
      }

      items[itemIndex].scanned = scanned;
      items[itemIndex].remaining -= 1;

      progress[jobId].items = items;
      return { ...state, progress };
    }

    case RESET_COUNTER:
      return { ...state, noRemaining: false, itemNotFound: false };

    case DELETE_SCANNED_ITEM: {
      const { jobId, productID, lotNumber, qty } = payload;
      if (!state.progress[jobId]) {
        return state;
      }
      const progress = { ...state.progress };
      const items = [...progress[jobId].items];
      const itemIndex = items.findIndex((item) => item.productID === productID);
      if (!items[itemIndex].scanned) {
        return state;
      }
      const scanned = items[itemIndex].scanned.filter((s) => {
        return s.lotNumber !== lotNumber;
      });
      if (items[itemIndex].scanned.length === scanned.length) {
        return state;
      }
      items[itemIndex].remaining += parseInt(qty);
      items[itemIndex].scanned = scanned;
      progress[jobId].items = items;
      return { ...state, progress };
    }

    case UPDATE_SCANNED_ITEM_QTY: {
      const { jobId, productID, lotNumber, qty, oldQty } = payload;
      if (!state.progress[jobId]) {
        return state;
      }

      const progress = { ...state.progress };
      const items = [...progress[jobId].items];
      const itemIndex = items.findIndex((item) => item.productID === productID);
      if (!items[itemIndex].scanned) {
        return state;
      }
      const scanIndex = items[itemIndex].scanned.findIndex((s) => {
        return s.lotNumber === lotNumber;
      });

      const qtyDiff = parseInt(qty) - parseInt(oldQty);

      if (scanIndex === -1) {
        return state;
      }
      if (items[itemIndex].remaining - qtyDiff < 0) {
        return state;
      }

      const scanItem = { ...items[itemIndex].scanned[scanIndex] };
      scanItem.qty = qty;
      items[itemIndex].scanned[scanIndex] = scanItem;
      items[itemIndex].remaining -= qtyDiff;
      progress[jobId].items = items;
      return { ...state, progress };
    }

    case UPDATE_TRACKING_NUMBER: {
      const { jobId, trackingNumber } = payload;
      if (!state.progress[jobId]) {
        return state;
      }
      const progress = { ...state.progress };
      const job = { ...progress[jobId], trackingNumber };
      progress[jobId] = job;
      return { ...state, progress };
    }

    default:
      return state;
  }
};
