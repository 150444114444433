import * as api from "../../api";
import * as types from "./types";
import { showError, hideLoader } from "./app";

export const fetchScanJobs = (status) => async (dispatch) => {
  dispatch({ type: types.FETCH_SCAN_JOBS });
  try {
    const data = await api.fetchScanJobs(status);
    dispatch({ type: types.FETCH_SCAN_JOBS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.FETCH_SCAN_JOBS_FAILED });
  }
};

export const fetchScanJob = (jobId) => async (dispatch) => {
  dispatch({ type: types.FETCH_SCAN_JOB });
  try {
    const data = await api.fetchScanJob(jobId);
    dispatch({ type: types.FETCH_SCAN_JOB_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: types.FETCH_SCAN_JOB_FAILED });
  }
  return null;
};

export const fetchScanJobBySoId = (soId) => async (dispatch) => {
  dispatch({ type: types.FETCH_SCAN_JOB });
  try {
    const data = await api.fetchScanJobBySoId(soId);
    dispatch({ type: types.FETCH_SCAN_JOB_SO_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: types.FETCH_SCAN_JOB_FAILED });
  }
  return null;
};

export const fetchSalesOrder = (soId) => async (dispatch) => {
  dispatch({ type: types.FETCH_SALES_ORDER });
  try {
    const data = await api.fetchSalesOrder(soId);
    dispatch({ type: types.FETCH_SALES_ORDER_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: types.FETCH_SALES_ORDER_FAILED });
  }
  return null;
};

export const fetchNewScanData = (soId) => async (dispatch) => {
  const data = await dispatch(fetchSalesOrder(soId));
  if (!data) {
    return null;
  }
  const _data = await dispatch(fetchScanJobBySoId(soId));
  if (!_data) {
    return null;
  }
  const { jobs } = _data;
  const { so, details } = data;
  so["job"] = jobs.length > 0 ? jobs.shift() : null;
  so["details"] = details;
  return so;
};

export const fetchBarCodeData = (barcode) => async (dispatch) => {
  dispatch({ type: types.FETCH_BARCODE_DATA });
  try {
    const data = await api.fetchBarCodeData(barcode);
    dispatch({
      type: types.FETCH_BARCODE_DATA_SUCCESS,
      payload: { barcode, product: data.product },
    });
    return data;
  } catch (error) {
    dispatch({ type: types.FETCH_BARCODE_DATA_FAILED });
  }
  return null;
};

export const fetchProductInfo = (sku) => async (dispatch) => {
  dispatch({ type: types.FETCH_PRODUCT_DATA });
  try {
    const data = await api.fetchProductInfo(sku);
    dispatch({ type: types.FETCH_PRODUCT_DATA_SUCCESS });
    return data;
  } catch (error) {
    dispatch({ type: types.FETCH_PRODUCT_DATA_FAILED });
  }
  return null;
};

export const registerProduct = (barcode, productId, type) => async (dispatch) => {
  dispatch({ type: types.REGISTER_PRODUCT });
  try {
    await api.registerProduct(barcode, productId, type);
    dispatch({
      type: types.REGISTER_PRODUCT_SUCCESS,
      payload: { barcode, productId, type },
    });
    return true;
  } catch (error) {
    dispatch({ type: types.REGISTER_PRODUCT_FAILED });
    dispatch(showError("Unable to register the product."));
  }
  return false;
};

export const unRegisterProduct = (barcode) => async (dispatch) => {
  dispatch({ type: types.UNREGISTER_PRODUCT });
  try {
    await api.unRegisterProduct(barcode);
    dispatch({ type: types.UNREGISTER_PRODUCT_SUCCESS });
    return true;
  } catch (error) {
    dispatch({ type: types.UNREGISTER_PRODUCT_FAILED });
    dispatch(showError("unregister product failed."));
  }
  return false;
};

export const createScanJob = (soId, items) => async (dispatch) => {
  dispatch({ type: types.CREATE_SCAN_JOB });
  try {
    const data = await api.createScanJob(soId, items);
    dispatch({ type: types.CREATE_SCAN_JOB_SUCCESS, payload: data });
    return data.job.jobId;
  } catch (error) {
    dispatch({ type: types.CREATE_SCAN_JOB_FAILED });
    dispatch(showError("unregister product failed."));
  }
  return null;
};

export const deleteScanJob = (jobId) => async (dispatch) => {
  dispatch({ type: types.DELETE_SCAN_JOB });
  try {
    await api.deleteScanJob(jobId);
    dispatch({ type: types.DELETE_SCAN_JOB_SUCCESS, payload: { jobId } });
    return true;
  } catch (error) {
    dispatch({ type: types.DELETE_SCAN_JOB_FAILED });
    dispatch(showError("Deleting scan job failed."));
  }
  return false;
};

export const scanPackingItem = (jobId, scannedData) => (dispatch, getState) => {
  dispatch({ type: types.SCANNED_DATA, payload: { jobId, scannedData } });
  const { noRemaining, itemNotFound } = getState().scan;
  if (noRemaining) {
    dispatch(showError("Extra item scanned!"));
  }
  if (itemNotFound) {
    dispatch(showError("Scanned item is not in the list!"));
  }
  if (noRemaining || itemNotFound) {
    dispatch({ type: types.RESET_COUNTER });
  }
};

export const deleteScannedItem = (jobId, productID, lotNumber, qty) => (
  dispatch
) => {
  dispatch({
    type: types.DELETE_SCANNED_ITEM,
    payload: { jobId, productID, lotNumber, qty },
  });
  dispatch(hideLoader());
};

export const updateScannedItemQty = ({
  jobId,
  productID,
  lotNumber,
  qty,
  oldQty,
}) => {
  return {
    type: types.UPDATE_SCANNED_ITEM_QTY,
    payload: { jobId, productID, lotNumber, qty, oldQty },
  };
};

export const updateScanJob = (jobId) => async (dispatch, getState) => {
  dispatch({ type: types.SAVING_SCANNED_ITEMS });
  const { progress } = getState().scan;
  const job = progress[jobId];
  if (!job) {
    dispatch({ type: types.SCANNED_ITEMS_SAVE_FAILED });
    return;
  }

  const { items } = job;
  await api.updateScanJob(jobId, items);
  alert("Scanned data has been uploaded to the server.");
  dispatch({ type: types.SCANNED_ITEMS_SAVED });
};

export const updateTrackingNumber = (jobId, trackingNumber) => async (
  dispatch
) => {
  dispatch({
    type: types.UPDATE_TRACKING_NUMBER,
    payload: { jobId, trackingNumber },
  });
  await api.updateTrackingNumber(jobId, trackingNumber);
};
