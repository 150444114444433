import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import {
  Button,
  Container,
  FormControl,
  InputGroup,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmPopup from "../../components/ConfirmPopup";
import { showError } from "../../redux/actions/app";
import {
  deleteScanJob,
  deleteScannedItem,
  fetchBarCodeData,
  fetchScanJob,
  scanPackingItem,
  updateScanJob,
  updateScannedItemQty,
  updateTrackingNumber,
} from "../../redux/actions/scan";
import Scanner from "../../services/Scanner/index";
import { withNavBar } from "../NavBar";

class StartScan extends PureComponent {
  constructor(props) {
    super(props);
    const { jobId } = props.match.params;
    this.state = {
      jobId,
      showConfirm: false,
      ready: false,
      showQtyUpdater: false,
      text: "",
      itemQty: 0,
      showScanTracking: false,
    };
    this.scanner = new Scanner();
  }

  componentDidMount() {
    this.startScan();
    const { jobId } = this.state;
    if (!this.props.progress[jobId]) {
      this.props.fetchScanJob(jobId);
    } else {
      this.setState({ ready: true });
    }
  }

  componentWillUnmount() {
    this.scanner.stop();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && nextProps.progress[prevState.jobId] && !prevState.ready) {
      return { ready: true };
    }
    return null;
  }

  getJob() {
    const { jobId } = this.state;
    return this.props.progress[jobId];
  }

  startScan() {
    this.scanner.start({
      onScan: this.onScan,
      type: "product",
    });
  }

  onScan = async ({ sCode, iQty, gtin, expDate, lotNumber, serialNumber, type }) => {
    // one scan per 400ms
    if (this.timer) {
      return;
    }
    this.timer = setTimeout(() => {
      this.timer = null;
    }, 400);

    if (this.state.showScanTracking) {
      this.updateTrackingNumber(this.state.jobId, sCode);
      return;
    }

    const { barcodes } = this.props;
    let product;
    if (!barcodes[gtin]) {
      const data = await this.props.fetchBarCodeData(gtin);
      try {
        product = data.product;
      } catch (error) {
        this.props.showError(
          "Product Not Found! Please make sure that the barcode is registered."
        );
        return;
      }
    } else {
      product = barcodes[gtin];
    }

    if (!product) {
      this.props.showError(
        "Product Not Found! Please make sure that the barcode is registered."
      );
    } else {
      const { jobId } = this.state;
      this.props.scanPackingItem(jobId, {
        sCode,
        iQty,
        gtin,
        expDate,
        lotNumber,
        serialNumber,
        type,
      });
    }
  };

  onDelete = async () => {
    this.closePopup();
    const deleted = await this.props.deleteScanJob(this.state.jobId);
    if (deleted) {
      this.props.history.push("/");
    }
  };

  closePopup = () => {
    this.setState({ showConfirm: false });
  };

  save = () => {
    const { jobId } = this.state;
    console.log("debug --> update scan", jobId);

    this.props.updateScanJob(jobId);
  };

  remove = () => {
    this.setState({ showConfirm: true });
  };

  deleteScannedItem = (jobId, productID, lotNumber, qty) => {
    if (window.confirm("Are you sure?")) {
      this.props.deleteScannedItem(jobId, productID, lotNumber, qty);
    }
  };

  updateQty = (jobId, productID, lotNumber, qty) => {
    this.willUpdateQty = { jobId, productID, lotNumber, qty };
    this.setState({ showQtyUpdater: true, itemQty: qty });
  };

  closeQtyUpdater = () => {
    const { itemQty } = this.state;
    if (itemQty !== this.willUpdateQty.qty) {
      const data = {
        ...this.willUpdateQty,
        qty: itemQty,
        oldQty: this.willUpdateQty.qty,
      };
      this.props.updateScannedItemQty(data);
    }
    this.setState({ showQtyUpdater: false });
  };

  handelQtyChange = (event) => {
    const { value } = event.target;
    this.setState({ itemQty: value });
  };

  decreaseItemQty = () => {
    this.setState((prevState) => {
      const itemQty =
        prevState.itemQty > 0 ? parseInt(prevState.itemQty) - 1 : 0;
      return { itemQty };
    });
  };

  increaseItemQty = () => {
    this.setState((prevState) => {
      return { itemQty: parseInt(prevState.itemQty) + 1 };
    });
  };

  updateTrackingNumber = async (jobId, trkNumber) => {
    await this.props.updateTrackingNumber(this.state.jobId, trkNumber);
    this.closeScanTracking();
  };

  scanTracking = () => {
    this.scanner.setType("-");
    this.setState({
      showScanTracking: true,
    });
  };

  closeScanTracking = () => {
    this.scanner.setType("product");
    this.setState({
      showScanTracking: false,
    });
  };

  renderButtons() {
    if (!this.state.ready) {
      return null;
    }
    return (
      <div>
        <hr />
        <div className="buttonContainer">
          <Button variant="warning" onClick={this.save}>
            Save
          </Button>
          <Button variant="danger" onClick={this.remove}>
            Delete
          </Button>
        </div>
      </div>
    );
  }

  renderTrackingButton() {
    const job = this.getJob();
    if (!job) {
      return null;
    }
    const items = job.items.filter((item) => {
      return typeof item.remaining === "undefined" || item.remaining > 0;
    });
    if (items.length > 0) {
      return null;
    }
    const { trackingNumber } = job;
    return (
      <>
        <hr />
        <h5>Tracking #</h5>
        <label>{trackingNumber}</label>
        <div className="buttonContainer">
          <Button variant="warning" onClick={this.scanTracking}>
            Scan
          </Button>
        </div>
      </>
    );
  }

  renderScanTracking() {
    return (
      this.state.showScanTracking && (
        <div className="qtyUpdater scanTracking">
          <label>Scanning...</label>
          <Button
            variant="primary"
            className="closeQty"
            onClick={this.closeScanTracking}
          >
            Cancel
          </Button>
        </div>
      )
    );
  }

  renderPendingItem(item, index) {
    return (
      <tr key={index}>
        <td>{item.sku}</td>
        <td className="text-right">{item.qty}</td>
        <td className="text-right">
          <b>{item.remaining || item.qty}</b>
        </td>
      </tr>
    );
  }

  renderPendingItems() {
    const job = this.getJob();
    if (!job) {
      return null;
    }
    const items = job.items.filter((item) => {
      return !item.isCompleted && (typeof item.remaining === "undefined" || item.remaining > 0);
    });
    if (items.length < 1) {
      return null;
    }
    return (
      <div>
        <hr />
        <h5>SO#: {job.soID}</h5>
        <Table striped hover size="sm">
          <thead>
            <tr>
              <th>SKU</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Need</th>
            </tr>
          </thead>
          <tbody>{items.map(this.renderPendingItem)}</tbody>
        </Table>
      </div>
    );
  }

  renderScannedItem = (item, index) => {
    const { jobId } = this.state;
    const { sku, productID } = item;
    return item.scanned.map((s, ndx) => {
      return (
        <div key={`${index}_${ndx}`} className="scanItemList">
          <div className="deleteScannedItem">
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => {
                this.deleteScannedItem(jobId, productID, s.lotNumber, s.qty);
              }}
            />
          </div>
          <div className="sku">
            {sku}
            <span>
              <b
                onClick={() => {
                  this.updateQty(jobId, productID, s.lotNumber, s.qty);
                }}
              >
                {s.qty}
              </b>
            </span>
          </div>
          <div className="scanInfo">
            GTIN: <i>{s.gtin}</i>{" "}
            <span className="lotNumber">
              Lot# <b>{s.lotNumber}</b>
            </span>
          </div>
        </div>
      );
    });
  };

  renderScannedItems() {
    const job = this.getJob();
    if (!job) {
      return null;
    }
    const items = job.items.filter((item) => {
      return (
        typeof item.remaining !== "undefined" && item.remaining !== item.qty
      );
    });
    if (items.length < 1) {
      return null;
    }
    return (
      <div>
        <hr />
        <div>
          <b>Scanned Items</b>
        </div>
        <div className="scanItemListHeader">
          SKU <span>Qty</span>
        </div>
        <div className="scanItems">{items.map(this.renderScannedItem)}</div>
      </div>
    );
  }

  renderQtyUpdater() {
    return (
      this.state.showQtyUpdater && (
        <div className="qtyUpdater">
          <InputGroup className="sm-3">
            <InputGroup.Prepend>
              <Button
                variant="outline-secondary"
                onClick={this.decreaseItemQty}
              >
                -
              </Button>
            </InputGroup.Prepend>
            <FormControl
              aria-describedby="basic-addon1"
              type="number"
              onChange={this.handelQtyChange}
              value={this.state.itemQty}
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={this.increaseItemQty}
              >
                +
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <Button
            variant="primary"
            className="closeQty"
            onClick={this.closeQtyUpdater}
          >
            OK
          </Button>
        </div>
      )
    );
  }

  render() {
    return (
      <Container>
        <h5>Scan Job ({this.state.jobId})</h5>
        {this.renderPendingItems()}
        {this.renderScannedItems()}
        {this.renderButtons()}
        {this.renderTrackingButton()}
        <ConfirmPopup
          message="All scanned data will be lost. Do you wan to delete it?"
          show={this.state.showConfirm}
          onYes={this.onDelete}
          onNo={this.closePopup}
          onClose={this.closePopup}
        />
        {this.renderQtyUpdater()}
        {this.renderScanTracking()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  progress: state.scan.progress,
  barcodes: state.scan.barcodes,
});

const mapDispatchToProps = {
  deleteScanJob,
  fetchScanJob,
  fetchBarCodeData,
  scanPackingItem,
  deleteScannedItem,
  updateScannedItemQty,
  updateScanJob,
  updateTrackingNumber,
  showError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavBar(StartScan));
